//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

.accordian-bg-products {
    background-color: $gray-300;
}

.nav-link {
    cursor: pointer;
}

html {
    font-size: 62.5%;
}

body {
    font-family: 'Inter', sans-serif!important;
    font-size: 1.4rem!important;
}

.btn {
    font-size: 1.4rem!important;
}

.cursor-pointer {
    cursor: pointer;
}

.lh-1 {
  line-height: 1.3!important
}

.dataTables_filter, .dataTables_paginate
{
    float: right;
    .pagination {
            .page-link {
                border-radius: 30px !important;
                margin: 0 3px;
                border: none;
                text-align: center;
                cursor: pointer;
            }
    }
}

.rdw-editor-main
{
    border: 1px solid  $gray-300;
    height: 239px;
}
.dz-message
{
     text-align: center;
    padding: 100px;
}

.react-datepicker-wrapper
{
    width: 100%!important;
}

.ReactModal__Overlay
{
  z-index: 1001!important;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.react-bootstrap-table{
    padding-top: 0px!important;
}

.square-switch
{
 .react-switch-bg
 {
 border-radius : 5px!important;
 }
}

input[type="range"] {
    -webkit-appearance: auto!important;
}

.chat-conversation-height {
        min-height: 500px;
}

.expand-cell{
    cursor: pointer!important;
}





.form-control {
    font-size: 1.44rem;
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.675rem;
    border-radius: 0.25rem;
    background-color: #74788D;
    color: #343A40;
}

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11000000;
    background: rgba(255, 255, 255, .8);
    .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 3px solid #3498db;
        z-index: 1500;
        border: 3px solid transparent;
        border-top-color: #3498db;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border: 3px solid #e74c3c;
            border: 3px solid transparent;
            border-top-color: #e74c3c;
            border-radius: 50%;
            animation: spin 3s linear infinite;
        }
        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border: 3px solid #f9c922;
            border: 3px solid transparent;
            border-top-color: #f9c922;
            border-radius: 50%;
            animation: spin 1.5s linear infinite;
        }
    }
}
.avatar {
    display: inline-block;
}
[data-letters]:before {
    content:attr(data-letters);
    display:inline-block;
    font-size:1em;
    width:2.5em;
    height:2.5em;
    line-height:2.5em;
    text-align:center;
    border-radius:50%;
    background:plum;
    vertical-align:middle;
    margin-right:1em;
    color:white;
}

.template-chose {
    box-sizing: border-box;
    &.selected {
        border: 1px solid #5664D2;
    }
}
.card-drop {
    cursor: pointer;
}

.dropdown-cutom-input {
  z-index: 99;
  display: none;
}

.list-group-item {
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #7688ff;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  //display: none;
}

::-webkit-scrollbar-thumb {
  background: #7688ff;
  border: 1px solid #fff;
  -webkit-border-radius: 10px;
  scroll-margin: 50px 0 0 50px;
}

body, html {
  scrollbar-color: #7688ff #fff;
  scrollbar-width: thin;
}


.video-uploader-row {
  justify-content: center;
  .video-uploader {
    margin: auto;
    width: 200px;
    height: 200px;
    position: relative;
    video {
      width: 100%;
      height: 100%;
      cursor: pointer;
      object-fit: fill;
    }
  }
}

#videoUploader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.modal-upload-progress {
  .progress {
    background-color: #ccc;
  }
}

.library-table {
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background-color: rgba(86, 100, 210, .15);
      }
    }
  }
}

.video-preview-frame {
  overflow: hidden;
}

.image-uploader {
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
  .input-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.img-actor {
  transform-origin : 50% 100%;
}


// subscribe

.subscribe-plan {
  margin-bottom: 5rem;
}

.rangeslider.rangeslider-horizontal {
    margin-bottom: 3.8rem;
}
.br-default {
  border-radius: 0.37rem
}

.card-subscribe {
  img {
    filter: invert(64%) sepia(83%) saturate(5514%) hue-rotate(217deg) brightness(84%) contrast(95%);
  }

  .price {
    font-size: 2.2rem;
  }
}


.StepRangeSlider__track {
    height: 12px;
    width: 100%;
    border-radius: 4px;
    background-color: #bbb;
    border: 1px solid #999;
    box-sizing: border-box;
    /* -webkit-appearance: slider-horizontal; */
}

.StepRangeSlider__thumb {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    border: 1px solid #888;
    background-color: #eee;
    /* -webkit-appearance: sliderthumb-horizontal; */
}

.StepRangeSlider__tooltip {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.8rem;
    color: #5664d2;
    font-weight: bold;
}

.StepRangeSlider__handle {
    cursor: grab;
}

.paycard.d-flex.flex-column.align-items-center.justify-content-center {
    height: 150px;
    background: black;
    color: white;
    border-radius: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

input.form-check-input {
    transform: translate(-8px,-11px);
    cursor: pointer;
}

.w-10 {
  width: 20%;
}

.border-lef {
  border-left:1px solid #E4E4E4;
}

.border {
  border: 2px solid #5664D2!important;
}

.svg-color {
  color: #74788D!important;
}

.accordion__button {
    background-color: transparent!important;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion {
    border: none!important;
    border-radius: 2px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-toggle:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 5px;
  bottom: 9px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-toggle {
  background-color: #5664D2;
}

input:focus + .slider-toggle {
  box-shadow: 0 0 1px #5664D2;
}

input:checked + .slider-toggle:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

/* Rounded sliders */
.slider-toggle.round {
  border-radius: 34px;
}

.slider-toggle.round:before {
  border-radius: 50%;
}

.btn-primary.btn-custom.w-100.btn-group .btn {
    background: white;
    color: black!important;
}

.btn-primary.btn-custom.w-100.btn-group .btn:focus {
    background: white;
    color: white!important;
    background: #5664D2!important;
}

.btn-primary.btn-custom.w-50.btn-group .btn {
    background: white;
    color: black!important;
}

.btn-primary.btn-custom.w-50.btn-group .btn:focus {
    background: white;
    color: white!important;
    background: #5664D2!important;
}

.search-box .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
    color: #5664D2;
}

.voicedata {
  border: 1px solid #E3E3E3;
}

.selectvoice {
  border: 1px solid #5664D2;
}

.voice-content {
    height: 400px;
    overflow-y: scroll;
}

.item-sound {
  height: 30px;
}

.item-sound {
  cursor: pointer;
  &:hover {
    background: #F1F5F7;
  }
}

.lh1 {
  line-height: 1;
}

.small-data-font {
  font-size: 1rem;
}

.small-circle {
  width: 10px;
  height: 10px;
  margin-right: .5rem;
  border-radius: 50%;
}

.datatopfix {
  transform: translateY(-25px);
}

button.dropdown-item {
    line-height: 1;
    z-index: 999999!important;
}

.bg-procesing {
  background: #909A94;

}

.dropdown-menu.dropdown-menu-right.show {
    right: 0px!important;
    top: 10px!important;
}


table.mb-0.intergration-table.table.video-table.table thead {
    transform: translateY(-10px);
}



table.mb-0.intergration-table.table.video-table.table tbody {
    &:after {
      content: '';
      width: 100%;
      height: 3px;
      background: black;
    }
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #eff2f7;
    border: 0!important;
}

.video-table tr.silver-two {
    &:after {
      background: url('../src/assets/images/border-bottom.jpg');
    width: 100%;
    position: absolute;
    height: 3px;
    content: '';
    display: block;
    left: 0;
    right: 0;
    transform: translateY(0px);
    background-size: 100% 100%;
    }
}

.video-table tr.silver-two:first-child {
    &:after {
      background: white;
    width: 100%;
    position: absolute;
    height: 6px;
    content: '';
    display: block;
    left: 0;
    right: 0;
    transform: translateY(-10px);
    background-size: 100% 100%;
    }


}


table th, .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 0px solid #eff2f7;
}



#searchInput {
  box-sizing: border-box;
  background-image: url('../src/assets/images/searchicon.png');
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 12px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#searchInput:focus {outline: 3px solid #ddd;}

table.mb-0.intergration-table.table.video-table.video-table-api-videos.table {
    max-height: 75vh;
    overflow-y: scroll;
}


.btn-primary.btn-custom.w-100.btn-group {
  height: 3.0rem;
  margin-bottom: 1rem;
  border-radius:12px;
  border: 1px solid #CED4DA;
  background: white;
  button {
    border-radius:12px!important;
    border:0!important;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 2.6rem!important;
    font-size: 1.3rem!important;
  }
}

  .search-box .search-icon-right {
    font-size: 24px;
    position: absolute;
    right: 1rem;
    top: 0;
    line-height: 38px;
    color: #5664D2;
}

input.form-control.rounded.left-position {
    padding-left: 10px;
}




.video-personalization {
  .form-control {
    font-size: 1.3rem!important;
}

p {
  font-size: 1.3rem;
}

}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
    padding-left: 40px;
    @include respond(laptop) {
      padding: 0px;
      padding-left: 20px;
    }
}

input[type="color"] {
    border: 0;
    position: absolute;
    border-radius: 50%!important;
    width: 20px!important;
    height: 20px!important;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    &:before {
      border-radius: 50%!important;
    }
     &:after {
      border-radius: 50%!important;
    }
}

input[type="color"]::-moz-color-swatch {
    border: 0;
    position: absolute;
    border-radius: 50%!important;
    width: 20px!important;
    height: 20px!important;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99999;

}

#color1 {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}
#color1::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
  padding: 0;
}
#color1::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 10px;
  padding: 0;
}


.background-image-full {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: calc(25% - 1rem);
  margin-bottom: 1.5rem;
  border-radius: 10px;
}

.height-color {
  height: 13.5rem;
  @include respond(desktop125) {
    height: 9.3rem;
  }
   @include respond(laptop) {
    height: 7.5rem;
  }

}

input[type=color]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

input[type=color]::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
}



 .wrapper__video{
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}


.img-actor {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0px)!important;
}

.ratio16-9 {
  padding-top:56%;
}

.objectfit-cover {
  object-fit: cover;
}


.add-pictures-row {
  display: flex;

  img {
    width: calc(25% - 1rem);
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    object-fit: cover;

    &:nth-child(4) {
      width: 25%;
      margin-right: 0;
    }
    &:nth-child(8) {
      width: 25%;
      margin-right: 0;
    }
    &:nth-child(12) {
      width: 25%;
      margin-right: 0;
    }
  }
}

.add-pictures-row-img {
  display: flex;

  .img-first {
    width: calc(25% - 1rem);
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
  }

  div {
    width: calc(25% - 1rem);
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;

    img {
      border-radius: 10px;
      width: 100%;
    }

    img {
      object-fit: cover;
    }

    &:nth-child(4) {
      width: 25%;
      margin-right: 0;
    }
    &:nth-child(8) {
      width: 25%;
      margin-right: 0;
    }
    &:nth-child(12) {
      width: 25%;
      margin-right: 0;
    }
  }
}

.delete-next-image-video-personalization {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.border-primary-color {
  border:1px solid #5664d2;
  padding: 5px;
  box-sizing: border-box;
}

img.pointer.background-image-full.height-color.upload-picture {
    /* border-radius: 22px!important; */
    object-fit: contain;
}


.border-box {
  box-sizing: border-box;
}

.video-uploader {
  img {
    max-height: 200px;
  }
}


.font-weight-semi-bold {
  font-weight: 600!important;
}




.background-canvas {
  .center {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
  }

  .left {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-right: auto;
      margin-top: auto;

  }

  .right {
     position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-left: auto;
      margin-top: auto;

  }

  .upperleft {
      position: absolute;
      top: 28px;
      left: 28px;
      right: 0;
      bottom: 0;
      margin-right: auto;
      margin-bottom: auto;
  }

  .uppercenter {
      position: absolute;
      top: 28px;
      left: 0;
      right: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: auto;
  }

  .upperright {
      position: absolute;
      top: 28px;
      left: 0;
      right: 28px;
      bottom: 0;
      margin-left: auto;
      margin-bottom: auto;
  }

  .middleleft {
      position: absolute;
      top: 0;
      left: 28px;
      right: 0;
      bottom: 0;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
  }

  .middlecenter {
    position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
  }

  .middleright {

    position: absolute;
      top: 0;
      left: 0;
      right: 28px;
      bottom: 0;
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;

  }

  .bottomleft {
    position: absolute;
      top: 0;
      left: 28px;
      right: 0;
      bottom: 28px;
      margin-right: auto;
      margin-top: auto;
  }

  .bottomcenter {
    position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 28px;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
  }

  .bottomright {
      position: absolute;
      top: 0;
      left: 0;
      right: 28px;
      bottom: 28px;
      margin-left: auto;
      margin-top: auto;
  }
}

.w-90 {
  width: 90%;
}

.transfarrfix {
    transform: translate(-7px, 22px);
}

.lambda {
  .btn {
    height: 2.8rem;
    font-size: 1.2rem;
  }
  .video-bottom {
    margin-bottom: 1.5rem;
    padding-top: 1.5rem;
}
  }


.size-24 {
  width: 24px;
  height: 24px;
}

input.position-relative.size-24 {
    width: 24px!important;
    height: 24px!important;
}

.colorpicker-relative.arrow-after.input-group {
    min-width: 44px;
}


.buttons-customs {
  .btn.btn-lg {
    @include respond(mac) {
      line-height: 1;
    }
  }
}

.overflow-hidden {
  overflow-x: hidden;
}

// E4E4E4

.accordion__item {
      border-top: 0px solid rgba(0, 0, 0, 0.1)!important;

  &::after {
    content: '';
    width: 110%;
    transform: translateX(-12px);
    height: 1px;
    background-color: #E4E4E4;
    display: block;
  }
}

input#actorScale[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input#actorScale[type=range]:focus {
  outline: none;
}
input#actorScale[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #F1F5F7;
  border-radius: 1px;
  border: 0px solid #000000;
}
input#actorScale[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #5664d2;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input#actorScale[type=range]:focus::-webkit-slider-runnable-track {
  background: #F1F5F7;
}
input#actorScale[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #F1F5F7;
  border-radius: 1px;
  border: 0px solid #000000;
}
input#actorScale[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #5664d2;
  cursor: pointer;
  margin-top: -7px;
}
input#actorScale[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input#actorScale[type=range]::-ms-fill-lower {
  background: #F1F5F7;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input#actorScale[type=range]::-ms-fill-upper {
  background: #F1F5F7;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input#actorScale[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #2497E3;
  cursor: pointer;
}
input#actorScale[type=range]:focus::-ms-fill-lower {
  background: silver;
}
input#actorScale[type=range]:focus::-ms-fill-upper {
  background: silver;
}


.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: transparent!important;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    color: red;
}


.dropdown-menu {
    max-height: 50vh;
    overflow-y: initial;
}

.actor-picture-height {
  height: 360px;
  @include respond(desktop125) {
    height: 260px;
  }
  @include respond(laptop) {
    height: 200px;
  }
}

.actor-picture-height-circle {
    height: 176px;
    border-radius: 100%;
    object-fit: contain;
    width: 176px;
}

.desktop-full {
  display: block;
  @include respond(desktop125) {
    display: none;
  }
}

.desktop-zoom {
  display: none;
  @include respond(desktop125) {
    display: block;
  }
  @include respond(laptop) {
    display: none;
  }
}

.laptop {
  display: none!important;
  @include respond(desktop125) {
    display: none!important;
  }
  @include respond(laptop) {
    display: block!important;
  }
}

.actor-chose {
  img:not(.background-image-full) {
    object-fit: unset;
    max-height:12rem!important;
    max-width: 100%;
    height: auto;
    width: auto;
    @include respond(desktop125) {
      height: 7.8rem!important;
    }
    @include respond(laptop) {
      height: 6.9rem;
    }
  }
  .background-image-full {
    width: 100%;
    height: 12rem;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading-small {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #5664D2;
  animation: spinner 0.6s linear infinite;
}

.loading-smaller {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #5664D2;
  animation: spinner 0.6s linear infinite;
}


.absolute-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.backloader {
    background: white;
    opacity: .85;
}

.picturezoom {
    transform: scale(1.8) translate(5px, 40px)!important;
}

.request-avatar {
  h1 {
    font-weight: normal!important;
  }

  a {
    text-decoration: underline!important;
  }
}


.trobottom-right {
  transform-origin: bottom right;
}
.trobottom-left {
  transform-origin: bottom left;
}

.trobottom {
  transform-origin: bottom;
}

.trup-right {
  transform-origin: top right;
}

.trupp-left {
  transform-origin: top left;
}

.trupp {
  transform-origin: top;
}

.trleft {
  transform-origin: left;
}

.trright {
  transform-origin: right;
}

.text-underline {
   text-decoration: underline!important;
}



.menu-links {
  a {
    color: #505d69;
  }
}


.icon-logo {
  img {
    max-height: 8rem;
  }
}


.sticker-popular {
  background-color: #FCB92C;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
}

.sticker-new {
  background-color: #1CBB8C;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
}


.sticker-exclusive {
  background-color: #C81DCC;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
}


.sticker-advanced {
  background-color: #3B3E41;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
}


.icon-logo {
  height: 8rem;
  margin-top: 5.5rem!important;
}


.sticker.mt-3.ml-3 {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.new-modal {
  background: #E4E4E4!important;
}

.pfield {
  padding: .5rem .8rem;
  background: #E4E4E4;
  margin-bottom: 1rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 133px;
  text-align: center;
}

.pfield-active {
  color: #1CBB8C;
  background: rgba(28, 187, 140, .2);
}

.mb1rem {
  margin-bottom: 1rem;
}



.modal-custom {
    .modal-dialog.modal-lg {
    width: 600px;
    max-width: 100%;
}
.modal-content {
  width: 600px!important;
}
}

.morefive {
  text-decoration: underline;
}

.cfield {
  padding: .5rem .8rem;
  background: #E4E4E4;
  margin-bottom: 1rem;
  border-radius: 5px;
  text-align: center;
  color: #1CBB8C;
  background: rgba(28, 187, 140, .2);
  white-space: nowrap;
  margin: .5rem .5rem
}


.custom-input {
  border: 0;
    border-bottom: 1px solid #808080;
}

.custom-color-text {
  color: #808080;
  font-weight: 500;
  font-size: 1.6rem;
}


.fixheightnew {
  height: auto;
  @include respond(desktop125) {
    height: 57px;
  }
  @include respond(laptop) {
    height: 76px;
  }

  @include respond(mac) {
    height: auto;
  }

}


.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.width134 {
  width: 27.8rem;
}

.modal-zapier .modal-content {
    width: 688px;
    max-width: 100%;
}

.background-silver-two {
  background: #b2b2b2!important;
  border: 0!important;

  &:hover {
    background: #929292!important;
  }
}


.modal-footer {
 border-top:  0
}

button.px-3.mr-3.background-red.btn.btn-secondary {
    background: #FF4A00;
    border: 0;
    outline: 0;
}

.b2b-bg {
  background: white;
  span.cfield {
    font-size: 1.2rem;
  }

  .pfield {
    font-size: 1.2rem;
    @include respond(laptop) {
      font-size: 1rem;
    }
  }


  .accordion__panel {
    padding-left: 1.7rem;
  }
}

.modal-b2b {
    max-width: 1400px;

    .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

    .modal-content {
      height: 90vh;
    }

    small {
      line-height: 1.2;
    }


   .custom-input {
    border: 0;
    border-bottom: 1px solid #808080;
    padding: 0;
}
input:checked + .slider-toggle:before {
    transform: translateX(19px);
}

.slider-toggle:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    transition: .4s;
}

.options-switch.mr-3 {
    height: 30px;
}

}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5664d2;
  color: #5664d2;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #5664d2;
  }
  50%,
  100% {
    background-color: #abacd1;
  }
}

.bbheight {
  min-height: 63rem;
}

.accordion {
    background: white;
}

.tableb2b {
  p.table-paragraph {
    font-size: 1.2rem;
    font-weight: 500;
  }
  p.table-small {
    font-size: 1rem;
    font-weight: 200;
  }

  table {
    width: 100%;
    margin: 0 auto;
  }

  input[type="checkbox"] {
    width: 20px;
}

th p {
    color: #808080;
    font-weight: 600;
    transform: translateY(2px);
}

p.selected-leeds {
  span {
    background: white;
  }
}

input.gopage {
    width: 45px!important;
    height: 20px!important;
}

button.btn-option {
    width: 23px;
    height: 23px;
    font-size: 14px;
    text-align: center;
    background: #A6ACB2 ;
    color:#E5E5E5 ;
    border: 0;
    outline: 0;
}

 .pagination.b2b {
     transform: translateY(5px);
     width: 100%;
 }


 .ipdCVu {
    padding: 0;
}

.search-results {
  background: #e4e4e4;
}




}

.semi-bold {
  font-weight: 600;
}


.subscription-new {
   .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

input:checked + .slider-toggle:before {
    transform: translateX(19px);
}

.slider-toggle:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 1px;
    background-color: #5664D2;
    transition: .4s;
}

.options-switch {
  height: 20px;
}

.slider-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D1D1D1;
    transition: .4s;
}

input:checked + .slider-toggle {
    background-color: #D1D1D1;
}

.badge-yellow {
  background: #FFE87A;
  color: black;
}

.big-font {
  font-size: 3.6rem;
}

h3 {
  color: #808080;
  font-size: 1.6rem;
}

  .mb-c-7 {
   margin-bottom: 7rem!important;
  }

  .border-silver {
    border: 2px solid #d9d9d9;
  }

  .border-blue {
    border:2px solid #abb2e9;
  }

  .h3-big {
    font-size: 2.2rem;
    color: black;
  }

.silver-color {
  color: #d9d9d9;
}

ul.free-forever {
  color: #808080;
  @include respond(tab-land) {
    list-style: none;
    padding-left: 0;
  }

  li {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    text-align: left;
    @include respond(tab-land) {
      text-align: center;
    }
  }
}

.small-12 {
  font-size: 1.2rem;
}

.buy .btn {
  min-height: 2.6rem;
}

.credits h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #808080;
}

.price h4 {
  font-weight: 600;
  color: black;
}

p.bigger-span {
  color: #FF3D60;
  font-size: 1.2rem;
}

.smaller-span {
  font-size: 1rem;
  color: #808080;
}

.border-table-subscription {

  border-bottom: 1px solid #808080;

}

.smaller {
  font-size: 1rem;
}

.clp-bg {
  clip-path: polygon(41% 0, 100% 0, 100% 100%, 0% 100%);
  transform: translateX(12px);
  color: white!important;
}

.bg-sb-1 {
  background: #B700B7;
  border-right: 3px solid #973abe;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  h3 {
    font-size: 3rem;
    @include respond(laptop) {
      font-size: 2.6rem;
    }
  }
  p {
    font-size: 1.4rem;
    @include respond(laptop) {
      font-size: 1.2rem;
    }
  }
}

.bg-sb-2 {
  background: #a1a5ff;
  border-right: 3px solid #6d98f2;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  h3 {
    font-size: 3rem;
    @include respond(laptop) {
      font-size: 2.6rem;
    }
  }
  p {
    font-size: 1.4rem;
    @include respond(laptop) {
      font-size: 1.2rem;
    }
  }

  .smaller {
      font-size: .9rem;
    }
}

.bg-sb-3 {
  background: #47d3d3;
  border-right: 3px solid #13c6c6 ;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  h3 {
    font-size: 3rem;
    @include respond(laptop) {
      font-size: 2.6rem;
    }
  }

  p {
    font-size: 1.4rem;
    @include respond(laptop) {
      font-size: 1.2rem;
    }


  }
}

.bg-sb-4 {
  background: #97979d;
  border-right: 3px solid #638a90;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

 h3 {
    font-size: 3rem;
    @include respond(laptop) {
      font-size: 2.6rem;
    }
  }
p {
    font-size: 1.4rem;
    @include respond(laptop) {
      font-size: 1.2rem;
    }
  }

}

ul.plan {
  li {
    color: #808080;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;

    @include respond(mac) {
      font-size: 1.2rem;
    }
  }
}

.bg-neutral {
  background: #f1f5f7;
}

.color-white {
  color:white;
}

h3.f21 {
  font-size: 2.0rem;
  color: black;

  @include respond(laptop) {
    font-size: 1.6rem;
  }
}

}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #505d69;
    background-color: transparent;
    border-color: #ced4da #ced4da #fff;
}


.nav-tabs-custom {
    border-bottom: 2px solid #D7DFE9;
}

.billing {
    .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

input:checked + .slider-toggle:before {
    transform: translateX(19px);
}

.slider-toggle:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 1px;
    background-color: #5664D2;
    transition: .4s;
}

.options-switch {
  height: 20px;
}

.slider-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D1D1D1;
    transition: .4s;
}

input:checked + .slider-toggle {
    background-color: #D1D1D1;
}



}

.nav-justified > .nav-link, .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: left;
}

.nav-tabs-custom .nav-item .nav-link::after {
    content: "";
    background: #5664d2;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

button.btn.btn-outline-light.color-white {
  &:hover {
    background-color: transparent!important;
  }
}

.w54px {
  width: 65px;
}

.circle-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  img {
    border-radius: 50%;
  }
}

.unlockb2b {
  i {
    font-size: 2.4rem;
    color: #ffb50d;
  }

  .mtf {
      margin-top: 0.8rem!important;
    }

    h2,p {
      color: #636E75;
    }

    .w37 {
      max-width: 100%;
      width: 37rem;
      display: block;
    }
}

.circle-logo img {
    width: 32px
;
    transform: translateX(2px);
}

.modal-payment .modal-content .modal-body {
    background: #f2f3f4!important;
}

.line-payment-modal {
  width: 50%;
  height: 2px;
  background: #f2f3f4;
}


.silver-box {
  background: #f9f9f9;
}

.bg-green-b2b {
  background: #ddf5ee;
}

.bg-modal-body {
  background: #e4e4e4;
}

.mh800 {
      min-height: 800px;
}

.budget {
  .cfield {
    background: #e4e4e4;
    color: #808080;
  }

  .cfield-active {
    background: #d6f3ea;
    color: #1CBB8C;
  }
}



// SEARCH DROPDOWN
.dropdownsearch {
            margin: 0;
            padding: 0;
            list-style: none;
            width: 100%;
            background-color: transparent;
            z-index: 99999!important;
            position: absolute;
            &:hover {
              height: 400px;
              width: 110%;
            overflow-y: scroll;
            overflow-x: visible;
            }

            input {
              width: 285px;
              position: sticky;
                position: -webkit-sticky;
                top: 50px;
            }


            &:hover .first-show {
              display: block;
            }

            &:hover {
              background: white;
              input {
                width: 100%;
                position: sticky;
                position: -webkit-sticky;
                top: 50px;
              }
            }
        }


        .dropdownsearch * {
            z-index: 99999!important;
        }

        .dropdownsearch li {
            position: relative;
            z-index: 99999!important;
        }

        .dropdownsearch li a {
            color: black;
            text-align: center;
            text-decoration: none;
            display: block;
            padding: 5px;
            z-index: 99999!important;
            font-size: 10px;
        }

        .dropdownsearch li ul {
            position: absolute;
            top: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            display: none;
            line-height: normal;
            background-color: #e4e4e4;
            z-index: 99999!important;

        }

        .dropdownsearch li ul li a {
            text-align: left;
            color: black;
            font-size: 10px;
            padding: 10px;
            display: block;
            white-space: nowrap;
            z-index: 99999!important;
        }

        .dropdownsearch li ul li a:hover {
            background-color: #5664d2;
            color: #ffffff;
            z-index: 99999!important;
        }

        .dropdownsearch li ul li ul {
            left: 100%;
            top: 0;
            z-index: 99999!important;
        }

        ul li:hover>a {
            background-color: #5664d2;
            color: #ffffff !important;
            z-index: 99999!important;
        }

        ul li:hover>ul {
            display: block;
            z-index: 99999!important;
        }


        .sc-bdfBQB.ipdCVu {
    background: white;
}

i.fas.fa-window-close.position-absolute {
    right: 0;
    top: 0;
    z-index: 999;
    cursor: pointer;
}

.w-33 {
    width: 95px;
}

.width95 {
  width: 95%!important;
  height: 30px!important;
}

.right-0 {
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
}

.grid-columns {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
  row-gap: 5px;
  font-size: 12px;
  @include respond(laptop) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(tab-land) {
    font-size: 10px;
  }
}

.flex-columns {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  @include respond(tab-land) {
    font-size: 10px;
  }
}


.b2b-bg .accordion__panel {
    padding-left: 1.7rem;
    padding-right: 1.7rem;
;
}

.rct-title {
    padding: 0 5px;
    font-size: 12px;
}





div#dropDownSearch .cfield {
    max-width: 100%!important;
    overflow: hidden;


    span {
      overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap!important;
    display: block;
    font-size: 10px;
    }
}

.mxw100 {
  max-width: 100%;
}

.hdtext {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap!important;
    display: block;
}

.d-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap:10px;
  max-width: 100%;


  .cfield {
    max-width: 100%!important;
    overflow: hidden;
    max-height: 25px;
  }

   span {
      overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap!important;
    display: block;
    font-size: 10px;
    }
}

tr input {
    vertical-align: middle;
    transform: translateY(0px);
}

thead p {
    margin-bottom: 6px;
}

.table-new {
  background: white;
  thead {
    background: white;
    line-height: 3;
  }
  tr {
    border-bottom: 2px solid rgb(239, 242, 247);
  }
}

.table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
    border-top-width: 0px!important;
    border-top-style: solid;
    border-top-color: rgb(239, 242, 247);
}

.bg-white {
  background-color: white;
}

.w-85 {
  width: 85%;
}

.editor-bg {
    display: block;
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.send-date {
  width: 28px;
  height: 28px;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #CCCCCC;
  border-radius: 50%;
  color: #808080;
  font-weight: bold;
  cursor: pointer;
}

.send-date-active {
  width: 28px;
  height: 28px;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D6F3EA;
  border-radius: 50%;
  font-weight: bold;
  color: #1CBB8C;
  cursor: pointer;
}

input:checked + .slider-toggle:before {
    transform: translateX(19px);
}

.slider-toggle:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    transition: .4s;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.nav-tabs-custom .nav-item .nav-link.active {
  &:hover {
    color: #5664d2!important;
  }
}

.white-label {
  height: calc(100vh - 271px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.smtp-sett-link {
  text-decoration: underline!important;
  color: red;
}
.cust-av {
  top: -8px
}
.text-underline {
  text-decoration: underline!important;
}

.csv-holder {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px 100px 10px 10px;
  border-radius: 5px;
  position: relative;
}

.remove-csv {
  cursor: pointer;
  top: -8px;
  right: -8px;
  position: absolute;
  z-index: 100;
}

.img-ai-rep {
  &:hover {
    outline: 2px solid #5664d2;
    transition: all .5s;
  }
  &.selected {
    outline: 2px solid #5664d2;
  }
}
.chews {
  &:hover {
   opacity: .5;
   transition: all .5s;
  }
}
.loader-wrapper-small {
  width: 100%;
  height: 100%;
  z-index: 1100;
  .loader {
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
      border: 3px solid #3498db;
      z-index: 1500;
      border: 3px solid transparent;
      border-top-color: #3498db;
      border-radius: 50%;
      animation: spin 2s linear infinite;
      &:before {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          right: 5px;
          bottom: 5px;
          border: 3px solid #e74c3c;
          border: 3px solid transparent;
          border-top-color: #e74c3c;
          border-radius: 50%;
          animation: spin 3s linear infinite;
      }
      &:after {
          content: "";
          position: absolute;
          top: 15px;
          left: 15px;
          right: 15px;
          bottom: 15px;
          border: 3px solid #f9c922;
          border: 3px solid transparent;
          border-top-color: #f9c922;
          border-radius: 50%;
          animation: spin 1.5s linear infinite;
      }
  }
}
.smtp-modal .modal-content {
  width: 80%!important;
}