/*
Template Name: Reachout.AI
Author: Themesdesign
Contact: themesdesign.in@gmail.com
File: Main Css File
*/


/* TABLES OF CSS

1. General
2. Topbar
3. Page Header
4. Footer
5. Right Sidebar
6. Vertical
7. Horizontal Nav
8. Layouts
9. Waves
10. Avatar
11. Helper
12. Demos
13. Plugins
14. Authentication
15. Email
16. Coming Soon
17. Timeline
18. Extra Pages

*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/round-slider";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/datatable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

@mixin respond($breakpoint) {
    @if $breakpoint == xxxsphone {
        @media only screen and (max-width: 21em) { @content }; // ~320
    }@if $breakpoint == xxsphone {
        @media only screen and (max-width: 23.5em) { @content }; // ~360
    }
    @if $breakpoint == xsphone {
        @media only screen and (max-width: 25em) { @content }; // ~400
    }
    @if $breakpoint == b-mobile {
        @media only screen and (max-width: 36em) { @content }; // ~576
    }
    @if $breakpoint == smphone {
        @media only screen and (max-width: 42em) { @content }; // 672
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: 46em) { @content }; // 736px
    }
    @if $breakpoint == bsphone {
        @media only screen and (max-width: 36em) { @content }; // 576px
    }
    @if $breakpoint == xxphone {
        @media only screen and (max-width: 47.9em) { @content }; // 736px
    }
    @if $breakpoint == phone-sm-land {
        @media only screen and (max-device-width: 736px) and (max-device-height: 414px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) { @content }; // iPad pro
    }
    @if $breakpoint == phone-land {
        @media only screen and (max-width: 900px) and (max-height: 500px) { @content };
    }
    @if $breakpoint == tabpro-land {
        @media only screen and (max-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) { @content }; // iPad pro
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; // 900px 56.25em
    }
    @if $breakpoint == lg {
        @media only screen and (max-width: 62em) { @content }; // 992px
    }
    @if $breakpoint == ipad1024 {
        @media only screen and (max-width: 64em) { @content }; // 1024px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; // 1200px
    }
    @if $breakpoint == mac {
        @media only screen and (max-width: 80em) { @content }; // 1280px
    }
    @if $breakpoint == laptop {
        @media only screen and (max-width: 86em) { @content }; // 1366px
    }
    @if $breakpoint == laptophdpi {
        @media only screen and (max-width: 90em) { @content }; // 1440px
    }
    @if $breakpoint == desktop125 {
        @media only screen and (max-width: 96em) { @content }; // 1536px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (max-width: 112.5em) { @content }; // 1800px
    }
    @if $breakpoint == all-desktop {
        @media only screen and (min-width: 85em) { @content }; //1360px+
    }

    @if $breakpoint == visina {
        @media only screen and (max-height: 422px) { @content };
    }
}

body {
    font-family: 'Inter', sans-serif!important;
    font-size: 1.4rem!important;

}

.campaign {
  padding-bottom: 250px;
}

.create-webhok-button {
  position: absolute;
  border: 1px solid #5664d2;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  transition: opacity .3s ease;
  &:hover {
    opacity: .7;
  }
  p {
    margin-bottom: 0;
  }
}

.icon-info {
    ul {
        li {
            font-size: 1.4rem;
            color: #171F46;
            font-weight: bold;
            opacity: .8;
            border-bottom: 2px solid transparent;
            transition: .5s linear all;
            cursor: pointer;
            z-index: 2;
            &:hover {
                opacity: 1;
                border-bottom: 2px solid #171F46;
            }
        }

        .active {
            opacity: 1;
            border-bottom: 2px solid #171F46;
        }
    }
}

.line-icon-info {
    width: 100%;
    height: 2px;
    background-color: #F1F5F7;
    transform: translateY(-12px);
}

.watch {
    .play {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F2F3F4;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    a {
        color: #1CBB8C;
        text-decoration: underline!important;
        font-size: 1.6rem;
        font-weight: bold;
        margin-left: 1rem;
    }
}

.add-more {
        a {
            color: #1CBB8C;
            text-decoration: underline!important;
            font-size: 1.6rem;
            font-weight: bold;
            margin-left: 1rem;
        }
}

.bg-color {
    background-color: #F1F5F7;
}

.footer {
    display: none!important;
}

.chose-actor-box {
    .box-item {
        width: 10rem;
        height: 10rem;
        background-color: #F1F5F7;
    }
}

.slidecontainer {
    width: 100%;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    color: red;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }


      .color-circle {
          width: 2.5rem;
          height: 2.5rem;
          background: #5664D2;
          border-radius: 50%;
          &.light_pink {
            background: #ffb6c1;
          }
          &.off_white {
            background: #f8f8ff;
          }
          &.warm_white {
            background: #FDF4DC;
          }
          &.soft_pink {
            background: #fdb0c0;
          }
          &.light_blue {
            background: #add8e6;
          }
          &.dark_blue {
            background: #77788d;
          }
          &.green_screen {
            background: #2fc98b;
          }
          &.light_orange {
            background: #ecc8a8;
          }
          &.soft_cyan {
            background: #6fc8cc;
          }
          &.soft_orange {
            background: #e4b370;
          }
          &.strong_cyan {
            background: #11b1cb;
          }
      }


  .group-top-form {
      transform: translateY(-60px);
  }

.btn-round {
    border-radius: 20px;
}

.group-bottom-form {
    transform: translateY(-36px);
}


.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 3rem;
    pointer-events: all;
    border-radius: 1.8rem;
    height: 2rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.175rem + 2px);
    left: calc(-2.25rem + 3px);
    width: calc(2rem - 2px);
    height: calc(2rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 50%;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #679E02;
    background-color: #679E02;
}

label.custom-control-label {
    padding-left: 12px;
    padding-top: 1px;
    font-weight: 400!important;
}

.color-green {
   color: #1CBB8C
}
.underline {
    text-decoration: underline;
}

// .nav-item {
//     color: #343A40;
//     border:0;
//     a {
//         color: #343A40;
//         border:0!important;
//         border-bottom: 2px solid transparent;
//         opacity: .8;
//         &:hover {
//             border-bottom: 2px solid #343A40!important;
//             opacity: 1;
//         }
//     }
// }

// a.active.nav-link {
//     color: #343A40;
//     border:0;
//     border-bottom: 2px solid #343A40!important;
//     opacity: 1;
// }


// .nav-tabs {
//     border-bottom: 2px solid #f1f5f7;;
// }

.center-text {
    transform: translateY(20px);
    font-weight: bold;
}



// Forms auth

a.logo img {
    height: 50px;
}


.custom-form {
    label {
        color: #343A40;
        font-weight: normal;
        font-size: 1.4rem;
    }

    input {
        height: 3.9rem;
    }

    input#exampleCheck1 {
        height: 1.4rem;
    }

    select {
        height: 39px!important;
    }
}

label.form-check-label {
    font-weight: normal;
}

.upfix {
    transform: translate(-10px, -5px);

    svg {
        margin-left:5px;
    }
}

.bgwhite {
    background:white!important;
}

.home-btn {
    display: none!important;
}

label.custom-control-label {
    color: #505D69!important;
    font-weight: 600;
}

.custom-control-label::before {
    position: absolute;
    top: 0.175rem;
    left: -1.5rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #CED4DA solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.175rem;
    left: -1.5rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

label.custom-control-label.mdtopfix::before,
label.custom-control-label.mdtopfix::after {
    transform: translateY(2px);
}

label.custom-control-label.mdtopfix {
    font-weight: normal!important;
}

.font-inter {
    font-family: 'Inter', sans-serif!important;
}

.font-rubik {
    font-family: 'Rubik', sans-serif!important;
}

.font-nunito {
    font-family: 'Nunito', sans-serif;
}



// font-sizes

h1 {
    font-size: 2.4rem;
    color: #343A40;
    font-weight: bold;
}

h2 {
    font-size: 2rem;

    &.analytic {
        font-size: 2.2rem;
    }
}



h4 {
    color: #171F46;
    font-size: 1.6rem;
}

h6 {
    font-size: 1.5rem;
}

.width-52r {
    width: 52rem;
    font-size: 1.2rem;
}

.width-12r {
    width: 12rem;
}

.width-16r {
    width: 16rem;
}

p {
    font-size: 1.4rem;
    &.small-regular {
        font-size: 1.2rem;
        color: #505D69;
    }
    &.small-bold {
        font-size: 1.2rem;
        font-weight: bold;
    }

    &.small-semi-bold {
        font-weight: 600;
    }

    &.card-text {
        color: #7E858E;
    }
}


.font-weight-semi-bold {
    font-weight: 600;
}

.font-weight-bold {
    font-weight: 700!important;
}

.silver-two {
    color: #7E858E;
}

.dropdown-item {
    font-size: 1.1rem;
}

.width-30 {
    width: 3rem;
    max-width: 200px;
}

.width-72 {
    width: 7.2rem;
}

.width-50 {
    width:5rem;
}

.max-width-600 {
    min-width: 605px;
    width: 605px;
    max-width: 100%;
}

//  margin spaces colors and other utl

.mb-50px {
    margin-bottom: 50px ;
}

.bg-transparent {
    background:transparent!important;
    border:0;
}

.bg-white {
    background:white;
}

.color-a-1 {
    color:#505D69!important
}

.color-a-2 {
    color:#636E75!important
}

.color-a-3 {
    color:#636E75!important
}
.color-subtitle {
    color: #767D85;
}

.color-alpha-analytics {
    color: rgba(126, 133, 142, .6)!important
}

.icons-mod i {
    font-size: 18px;
    font-weight: bold;
}

.icons-mod svg {
    transform: translateY(-1px);
}

.anal-mod {
    font-size: 1.44rem;
    transform:translateY(5px);
}

.mb-40 {
    margin-bottom: 4rem;
}

.badge.badge-pill.d-block.badge-sms.my-3.font-nunito.py-1.mr-2 {
    background: #CCAB36;
    color: white;
    width: 4.8rem;
}

.bg-body {
    background:#F1F5F7;
}

.color-black {
    color: #343A40!important;
}

.padding-37 {
    padding:3.7rem 0;
}

.color-black0 {
    color: black;
}

.color-show-close-advanced {
    color: #5664D2!important;
}

.btn {
    min-height: 3.9rem;
    border-radius:0.37rem!important;
}

.form-control {
    border-radius:0.37rem!important;
}

.line-bottom {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 69px;
    background: #E4E4E4;
}

// Layout modification

// #page-topbar {
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 230px;
//     z-index: 1002;
//     background-color: #ffffff;
// }

.row {
    max-width: 100%;
}

// .navbar-header.ml-4.mr-2 {
//     max-width: 100%!important;
// }

.left-home-wrap {
    width: 240px;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #252B3B;
}

.right-home-wrap {
    width: calc(100% - 240px);
}

// .dropdown-menu.dropdown-menu-right span {
//     display: none;
// }

// .dropdown-menu.dropdown-menu-right.show {
//     display: flex;
// }

// button.btn.header-item.waves-effect span {
//     display: none;
// }

.ml22 {
    margin-left:2.2rem;
}


.d-none.d-sm-inline-block.dropdown span {
    display: none;
}

.container-fluid {
    padding-left: 2.2rem;
    padding-right:2.2rem;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: auto;
}

// sidebar header

div#sidebar-menu .sidebar-data__title h4 {
    color: white;
    font-weight: 400;
    font-size: 1.4rem;
}

div#sidebar-menu {
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    i {
        font-size: 1.6rem!important;
        margin-right: 1.2rem;
    }

    .progress {
        display: flex;
        height: .7rem;
        overflow: hidden;
        line-height: 0;
        font-size: 0.675rem;
        border-radius: 2.7rem;
        background-color: white;
        color: #343A40;
    }

    .sidebar-data {
        padding: 0 20px;

        &__title {
            color: white;

            i {
                font-size: 1.6rem;
            }

            h4 {
                color: white;
                font-weight: 400;
            }
        }
    }

    p {
        font-size: 1.2rem;
        font-weight: normal;
        font-family: 'Nunito', sans-serif!important;
        color: white!important;

       &.small {
            font-size: 1rem!important;
            color: white;

            span {
                color: #D3D3D3;
                margin-left: .5rem;
            }
        }
        &.color {
            color: #D3D3D3;
        }
    }
    h5 {
        color: white;
        font-weight: bold;
        font-family: 'Nunito', sans-serif!important;
    }
}

// Campaigns Start



.campaign {
    .progress {
        display: flex;
        height: 1rem;
        overflow: hidden;
        line-height: 0;
        font-size: 0.675rem;
        border-radius: 0.25rem;
        background-color: #f1f5f7;
        color: #343A40;
    }

    button.dropdown-toggle.btn.btn-secondary {
        background: white;
        border: 0;
        color: #212529 !important;
        height: 3.9rem;
    }


    .btn-campaign {
        background: #36C9FF;
        transform: translateY(-8px);
        border: 0;
    }

    &__sort {
        ul {
            padding:0;
            margin:0;
            list-style: none;
            width: 100%;
            max-width: 52.8rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid #D7DFE9;
            &:after {
                content: '';
                display: inline-block;
                width: 1px;
            }
        }

       li {
           border-bottom: 1px solid transparent;
           color: #acb2b8;
           &.active {
               color: #171F46;
               &:after {
                    content: '';
                    width: 25px;
                    height: 1px;
                    display: block;
                    background: #4aa3ff;
                    transform: translateY(12px);

               }
           }
           &:hover {
               color: #171F46;
            &:after {
                content: '';
                    width: 25px;
                    height: 1px;
                    display: block;
                    background: #4aa3ff;
                    transform: translateY(12px);
            }
           }
       }
    }

    .sort {
        li {
            color: #343A40;
            margin: 0 1.5rem;
            font-size: 1.44rem;
            &:last-child {
                margin: 0;
            }
        }
    }

    .filter {
        button.dropdown-toggle.btn.btn-secondary {
            background: white;
            border: 0;
            color: #212529!important;
        }
    }

    .badge.badge-pill.d-block.badge-danger.my-3.font-nunito.p-2 {
        max-width: 94px;
        font-size: 1.2rem;
    }

    .badge.badge-pill.badge-default.my-3.font-nunito.p-2 {
        background: #EFF2F7;
        width: 13.5rem;
        font-size: 1.2rem;
        color: #212529;
    }



    .table-responsive td,
    .table-responsive th {
        vertical-align: middle!important;
    }
    .table-responsive tr {
        height: 7rem;
    }
}

// Campaigns End

.blbg {
    background: #EFF2F7;
}

input.form-control.rounded {
    height: 39px;
}

input.form-control.rounded::placeholder {
    font-size: 1.44rem;
    font-family: 'Nunito';
}

.trbtn-fix {
    transform: translateY(16px);
}

// Analytics

.analytics {
    .analytic-badge {
      &.badge-success {
        color: #1CBB8C;
        background: rgba(28, 187, 140, .2)!important;
      }
    }

    tr.text-uppercase {
        line-height: 6rem;
        transform: translateY(-15%);
    }

    .progress {
        display: flex;
        height: .5rem;
        overflow: hidden;
        line-height: 0;
        font-size: 0.675rem;
        border-radius: 0.25rem;
        background-color: #f1f5f7;
        color: #343A40;
    }

    tr.silver-two {
        height: 7rem;
    }

    .badge.badge-pill.d-block.badge-inactive.my-3.font-nunito.py-1.px-4 {
        background: #cbced2!important;
        color: white!important;
    }
}

input.form-control.rounded.date {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 0px;
    padding-right: 68px;
}

// Intergradtions

.intergrations {
    .badge.badge-pill.d-block.badge-success {
        width: 48px;
        font-size: .8rem!important;
        height: 16px;
        padding-top: 0.35rem!important;
    }

    ul.nav.nav-tabs.nav-tabs-custom.nav-justified.text-uppercase.nav.nav-tabs {
        border-bottom: 1px solid #D7DFE9;
    }

    .badge.badge-pill.d-block.badge-inactive.my-3 {
        width: 48px!important;
        background: #cbced2!important;
        font-size: .8rem!important;
        color: white;
        height: 16px;
    }

    a.active.nav-link {
        background: transparent!important;
        color: #171F46!important;
    }

    // #cbced2
}

// Campaign

.campaign {
    .budget {
        border-top: 1px solid #E4E4E4;
    }

    .line-campaign-bottom {
        width: 100%;
        height: 1px;
        background:#E4E4E4;
        transform: translateY(-85px);
    }

    input,select {
        height: 39px!important;
    }


}

.back-button {
    width: 28px;
    height: 28px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.step-box {
    width: 25px;
    height: 25px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius:50%;

    i.fas.fa-check-circle.text-success {
        font-size: 25px;
    }
}

.color-white {
    color: white;
}

// Video Personalization

select,
input {
    height: 3.9rem!important;
}

.modal-dialog {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.video-personalization {
    .sound {
        border: 1px solid #ced4da;
        padding: 0 1rem;
        border-radius: 0.37rem;
    }

    .long-button {
        flex-grow: 1;
        padding-right: 1rem;
        //padding-left: 2.2rem;
    }

    .add-video {
        button.dropdown-toggle.btn.btn-secondary {
            height: 8rem;
            background: white;
            color: #5664D2!important;
            width: 11rem;
            border: 1px solid #CED4DA;
            border-radius: 0.25rem;
        }
    }

}

.bg-body.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.675rem;
    border-radius: 0.25rem;
    background-color: #F1F5F7!important;
    color: #343A40;
}

button.btn.btn-outline-primary.bg-white {
    &:hover {
        color: #5664D2;
    }
}



ul.nav-tabs-custom.custom-intergration.text-uppercase.nav.nav-tabs li.nav-item {

    a.active.nav-link {
        font-weight: bold;

        svg {
            filter: invert(39%) sepia(61%) saturate(1216%) hue-rotate(209deg) brightness(86%) contrast(90%);
        }
    }
    padding: 15px 0;
    margin-right: 18px;
    border-bottom: 1px solid transparent;



    &:hover {
        border-bottom: 1px solid #5664D2;
        a {
            color: #5664D2;
        }


        svg {
            filter: invert(39%) sepia(61%) saturate(1216%) hue-rotate(209deg) brightness(86%) contrast(90%);

        }
    }


}

.tab-content {
    padding: 2rem 0;
}

.tab-pane.active .row {
    margin: 0;

    .watch a {
        color: #5664D2;
        text-decoration: none!important;
        font-size: 1.3rem;
        font-weight: 400;
        margin-left: 1rem;
        text-decoration: none;
    }
}

.add-more {
    cursor: pointer;
    p {
        color: #5664D2;
        transform: translateY(2px);
    }
}

.color-dropdown  {
    .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
        color: #fff;
        background-color: transparent;
        border: 0;
        outline: 0;
    }

    button {
        background: transparent;
    border: 0;

    &:hover {
        background: transparent;
    }
    }
}

.select-actor {
    box-sizing: border-box;
    border: 1px solid transparent;
    &.selected {
        border: 1px solid #5664d2;
    }
}

.color-table-bold {
    color: #343A40!important;
}

.video-table th {
    color: #7E858E;
    font-size: 1.2rem;
}

.video-table {
    tr.silver-two:not(a) {
        line-height: 4;
    }

    .custom-control.custom-checkbox {
        transform: translateY(17px);
    }
}

// allert

.d-flex.align-items-center.text-custom-alert.position-relative {
    //min-width: 300px;
}

p.text-succes {
    max-width: 495px!important;
    width: auto!important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
}

.custom-alert {
    display: block;
    position: fixed;
    z-index: 3333;
    bottom: 20px;
    height: auto;
    right: 20px;
    overflow: visible;
    color: white;
    line-height: 1.3 !important;
    text-overflow: ellipsis;
    width: auto;
    max-width: 800px!important;

    p {
      width: 100%;
      white-space: nowrap;
      margin: 0;
      padding-right: 0;
    }

    .error-class-padding {
      padding-right: 40px;
    }



    .alert-icon {
        position: absolute;
        right: 20px;
    }


    .box-circle {
        width: 50px;
        height: 50px;
        background: #2d303f;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-right: 1rem;
        padding: 0 1rem;


        .circle-alert {
            width: 15px;
            height: 15px;
            border-radius: 50%;
        }
    }

    .danger {
        background: #373d4f;
        border-radius: 4px;
        .circle-alert {
            background: red;
        }
    }

    .success {
        background: #373d4f;
        border-radius: 4px;
        .circle-alert {
            background: green;
        }
    }



    .alert {
        text-align: center;
    }
}


// vertical acordian

.border-wrap {
    border: 1px solid #E3E3E3;

    a.active.nav-link {
        border: 0;
        background: #F1F5F7!important;
        color: #5664D2!important;
    }
    a.nav-link {
        padding: 1rem 1rem;
    }
}

.border-wrap-second {
    border: 1px solid #E3E3E3;
    border-left: 0;
}

.border-bottom-line {
    border-bottom: 1px solid #E3E3E3;
}

.modal-button-default {
    border: 0;
    color: #212529!important;

    &:hover {
        background: #f1f5f7;
    }
}


// landing page

.width-74r {
    width: 100%;
    max-width: 74.4rem;
}

.btn-radius {
    border-radius: 20px!important;
}


// publish page

.position-center {
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    width: 200px;

    p.text-underline {
        text-decoration: underline;
        cursor: pointer;
        color: #74788D;
    }
}

.width-12r {
    width: 12rem;
}


//  Intergrtions webhooks

.webhooks-table {
    thead {
        background: white;
        line-height: 3.3;
        border: 1px solid #ced4da;

    }

    tr {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
}

.margin-big {
    padding-top: 10rem;
}

.intergration-table {
    background: white;
}


.tfix {
    transform: translateY(3px);
}

td.d-flex.hfix div {
    transform: translateY(16px);
}

td.d-flex.hfix svg {
    transform: translateY(18px);
}

.color-active {
    color: #252B3B!important;
}

.width-34r {
    width: 314px!important;
}

// zapier autentification \

.line-zapier {
    border-bottom: 1px solid #F1F5F7;
}

.border-primary {
    border-bottom: 1px solid #5664D2;
}

.copyapikey {
    transform: translateY(-142%);
}

.play-video-box {
    background: #F1F5F7;
    height: 30rem;
}

.button-zap {
    white-space: nowrap;
    line-height: 1.8;
}

input#apikey {
    padding-right: 113px;
}

.w-6r {
    width: 6rem;
}

// Loading Page

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11000000;
    background: rgba(255, 255, 255, .8);
    .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 3px solid #3498db;
        z-index: 1500;
        border: 3px solid transparent;
        border-top-color: #3498db;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border: 3px solid #e74c3c;
            border: 3px solid transparent;
            border-top-color: #e74c3c;
            border-radius: 50%;
            animation: spin 3s linear infinite;
        }
        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border: 3px solid #f9c922;
            border: 3px solid transparent;
            border-top-color: #f9c922;
            border-radius: 50%;
            animation: spin 1.5s linear infinite;
        }
    }
  }

//   chat page

.chat {
    min-height: 100vh;
}

.play-video-chat {
    position: absolute;
    left: 1rem;
    bottom: 0;
    font-size: 4rem;
    color: white;
    cursor: pointer;
}

.chat-info {
    .text-message {
      border-left: 3px solid #5664d2;
    }
}

.cta-button {
  display: block;
  color: red;
  padding: 5px 5px;
  border-radius: 20px;
  border: 1px solid red;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  transition: opacity .3s ease;
  &:hover {
    color: red;
    opacity: .7;
  }
}

p.chat-text {
    display: block;
    padding: 10px;
    border-radius: 14px;
    color: white;

    span {
        padding: 10px;
        border-radius: 14px;
        background: #5664d2;
    }
}
.chat-placeholder {
    padding-right: 50px;
}
.h-0 {
    height: 0;
}


.dataTables_paginate {
    li.active.page-item {
        width: 30px;
    }
}


//  dropdown style

.dropdown-item {
    font-size: 1.2rem;
    border-bottom: 1px solid #CCD0F1;
    padding: 1rem 2rem;

    &.lineH-fix {
      line-height: 8px;
    }

    &:last-child {
        border-bottom: 0;
    }
}


.dropdown-menu button {
    border-bottom: 1px solid #CCD0F1;

    &:hover {
        background: #F1F5F7;
    }
}




.video-personalization-modal-gif {
    .center {
        margin: 0 auto;
    }

    .right {
        margin-left: auto;
    }

    .left {
        margin-right: auto;
    }

    .video-content {
        top: 40%;
        left: 165px;
        transform: rotate(5deg);
        width: 33.8rem;
        text-align: center;
        height: 9rem;
        max-height: 24rem;
        overflow-y: auto;
        line-height: 40px;
    }
}

#thumbnailPreview {
  width: 640px;
  height: 360px;
  .video-content {
    top: 40%;
    left: 165px;
    transform: rotate(5deg);
    width: 33.8rem;
    text-align: center;
    height: 9rem;
    max-height: 24rem;
    overflow-y: auto;
    line-height: 40px;
}
}

.dropdown-cutom-input {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    li {
        border-bottom: 1px solid #CCD0F1;

        &:last-child {
            border-bottom: 0;
        }
    }
}
.chat {
  .name {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #8dd8e0;
    padding-left: 0;
  }
}
.text-holder {
  position: relative;
  .send-msg-arr {
    position: absolute;
    height: 30px;
    border: 1px solid #5664d2;
    width: 30px;
    color: #5664d2;
    border-radius: 50%;
    cursor: pointer;
    top: 12px;
    right: 10px;
    transition: opacity .3s ease;
    background-color: transparent;
    &:hover {
      opacity: .7;
    }
    .fa-location-arrow {
      margin-top: 6px;
      margin-left: 0px;
    }
  }
}

.video-lead {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

}
.lead-page-main {
  background-color: transparent;
}

.lead-page-right {
  background-color: #fff;
}
.send-msg-out {
  position: absolute;
  bottom: 0px;
  left: 10px;
  right: 10px;
}

.outer-iframe {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* ratio 16/9 */
  iframe {
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.cover-bckg {
  background-size: cover!important;
}

.iframed {
  .chat-box{
    margin-top: 25px;
  }
}
.modal-customized {
    .modal-content {
        border:0!important;
    }
    i {
        font-size: 7rem;
        padding: 1rem 0;
        color: white;
    }
}

.gif-edits {
  font-size: 24px;
  font-family: 'Caveat', cursive;
  font-weight: 500;
}

.target {
  position: absolute;
  width: 100px;
  height: 100px;
  text-align: center;
  color: #333;
  border: 1px solid #333;
  box-sizing: border-box;
}


.video-personalization-modal-gif {
  width: 640px;
  height: 360px;
  margin: auto;
  .video-edit {
    text-align: center;
    img {
      width: 640px;
      height: 360px;
      text-align: center;
    }
  }
  .gif-holder {
    display: inline-block;
  }
}

.code-snippet pre {
  display: block;
  text-align: left;
  word-break: break-word;
  word-wrap: break-word;
  font-family: Monospace,serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.code-snippet .hljs {
  background-color: #222;
  color: #fff;
  white-space: pre-wrap;
  max-height: 494px;
  padding: 20px;
  display: block;
  overflow-x: auto;
  padding: .5em;
  background: #2b2b2b;
  color: #bababa;
}
.img-gif-set {
  height: 360px!important;
  width: 640px!important;
}
#imgGif {
  width: 640px!important;
  max-width: 100%!important;
  height: 360px;
  position: relative;
}

.outer-iframe {
    width: 100%;
    position: relative;
    padding: 0;
}

iframe#myId {
    height: 413px;
}
.modal-upload-progress {
  z-index: 99999999;
}
.checkout {
    label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
        font-size: 18px;
      }

      button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #6772e5;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
      }

      button:hover {
        color: #fff;
        cursor: pointer;
        background-color: #7795f8;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
      }

      input,
      .StripeElement {
        display: block;
        margin: 10px 0 20px 0;
        max-width: 500px;
        padding: 10px 14px;
        font-size: 1em;
        font-family: 'Source Code Pro', monospace;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border: 0;
        outline: 0;
        border-radius: 4px;
        background: white;
      }

      input::placeholder {
        color: #aab7c4;
      }

      input:focus,
      .StripeElement--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        transition: all 150ms ease;
      }
}
.creds-info-holder {
    position: relative;
    span {
        position: absolute;
        transform: translateX(-50%);
        white-space: nowrap;
    }
}

.card-name-holder {
    position: absolute;
    font-size: 18px;
    transform: translateY(-20px);
}

.remove-custom-field {
    margin-left: 15px;
    cursor: pointer;
}
.btn-selectable {
    color: #5664d2;
    border-color: #5664d2;
    font-size: 14px;
    line-height: 14px;
    min-height: 0!important;
    margin-left: 15px;
    width: 150px;
    white-space: nowrap;
    margin-top: 7px;
    margin-bottom: 7px;
    &:focus {
        outline: none!important;
        box-shadow: none!important;
    }

    &:hover {
        background-color: #5664d2;
        color: #fff;
    }

    &.btn-selectable-selected {
        background-color: #5664d2;
        color: #fff;
    }
}

.wrap-fl {
    flex-wrap: wrap;
}

.link-normal {
  color: white!important;
}

.select-capaings-by-status {
  li {
    cursor: pointer;
  }
}

.select-dropdown {
  z-index: 10;
  box-shadow: 0px 3px 5px #ccc;
}

.pointer {
  cursor: pointer;
}

.remove {
  z-index: 10;
  display: inline-block;
  position: relative;
}

.next-vid-video-personalization {
  position: relative;
  .delete-next-vid-video-personalization {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
}
.list-searh-drp {
  position: absolute;
  z-index: 20;
}
.pt-40 {
  padding-top: 40px;
}

.dropdown-analytics {
  max-height: 200px;
  overflow-y: auto;
}

.share-link-txt {
  width: 100%;
}

@media print {
  .hide-print {
    visibility: hidden;
  }
}
.remove-styling-a {
  color: white;
  &:hover {
    color: white;
  }
}
.remove-styling-a-second {
  color: #505d69;
  &:hover {
    color: #505d69;
  }
}

#HW_badge {
  position: absolute;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #ff3d60;
  border-radius: 50%;
  top: -2px;
  left: 22px;
}
.bell {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  top: 20px;
  right: 14px;
}
.main-loader {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 1010;
  background: #fff;
}
.badge-api {
  padding-bottom:0!important;
  line-height:12px;
}
.info-holder-api-videos {
  vertical-align: middle;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.video-table-api-videos tr td {
  vertical-align: middle;
}
.label-ap-vd {
  margin-top: -10px;
}

.library-campaign-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: inline-block;
}

.dropdown-menu.dropdown-menu-right.show {
  position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-139px, 50px, 0px);
    z-index: 99999;
    background-color: #fff;
}
.dropdown-menu {
  max-height:50vh;
  overflow-y:scroll;
}
.search-header-drop-down {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
}
.fixed-search-drp {
  padding-top: 0;
  input {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    border: 0;
    &:focus {
      border: 0;
      outline: none;
    }
  }
}

.selected-actor {
  outline: 1px solid #5664d2;
  box-sizing: border-box;
}

.btn-primary.btn-custom.w-100.btn-group {
  .btn {
    &.btn-secondary{
      &.selected-type {
        color: white !important;
        background: #5664D2 !important;
      }
    }
  }
}

.colors {
  display: inline-block;
  width: 78px;
  height: 78px;
  margin-left: 5px;
  border: 1px solid #ccc;
}
.upl-holder {
  width: 95px;
  height: 93px;
  img {
    width: 100%
  }
}

.btn-primary.btn-custom.w-50.btn-group .btn {
  &.btn-selectable-selected {
    background-color: #5664d2!important;
    color: #fff!important;
  }
}

.scrollable-boxes {
  max-height: 300px;
  overflow-y: auto;
}

.bg-body-add-vid {
  background-color: #F1F5F7;
}
.watermark_var {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#imageDirectUploader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
}

#videoDirectUploader, #soundUploaderHide, #voiceUploaderHide {
  position: absolute;
  width:0;
  height:0;
  z-index: -10;
  visibility: hidden;
}

#thumbnailPreview {
  .border {
    border: 0!important;
    width: 100%!important;
    flex: 0 0 100%!important;
    max-width: 100%;
    margin-top: 25px;
  }
  .target-clone {
    border: 0!important;
    color: #333;
    box-sizing: border-box;
    overflow-y: visible;
  }
}

.hidden {
  display: none;
}


.dropdown-menu.dropdown-menu-right.show a {
 color:#212529;
}

#videoUploadFile {
  z-index: -1;
  width: 0;
  position: absolute;
  height: 0;
  opacity: 0;
}

.text-orange {
  color: #FFA500!important;
}

#scriptEditable {
  height: 105px;
  overflow-y: scroll;
  resize: vertical;
}

#sidebar-menu ul li > a {
  background-color: transparent!important;
}

.dropDownSearch {

  .custom-drp {
    list-style: none;
    height: 0;
    bottom: 15px;
    z-index: 999999!important;
  }
  .list-group-item {
    position: relative;
    &:hover {
      background-color: rgba(86, 100, 210, .5)!important;
      a {
        background-color: transparent!important;
        color: #505d69!important;
      }
    }
    a {
      &:hover {
        background-color: transparent!important;
        color: #505d69!important;
      }
    }
  }

  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
    overflow: visible;
  }
  .dropdown-toggle {
    position: relative;
    width: 100%;
    color: #505d69;
    font-size: 14px;
  }
  .chew-drp {
    position: absolute;
    right: 5px;
  }
  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  .list-group-item {
    color: #505d69;
    font-size: 14px;
  }

}
.dropDownSearchRole {
  z-index: 1;
  background-color: #fff;
  position: relative;

  .wrapper {
  }

  ul {
    background-color: #fff;
    max-height: 300px;
    // use scrolling if necessary, but don't show a horizontal scrollbar
    overflow-x: hidden;
    overflow-y: auto;
    cursor: pointer;
  }

  .parent ul {
    max-height: 300px;
    height: 300px
  }

  li {
    white-space: nowrap;
    // the submenus cannot have a positioned parent inside the scrollable list
    cursor: pointer;
    // submenu
    .wrapper {
      position: absolute;
      z-index: 999999999;
      display: none;
      left: 98%;
      cursor: pointer;
    }

    // display the submenu when we hover on the menu item
    &:hover > .wrapper {
      display: block;
    }
  }

  ul {
    cursor: pointer;
    color: #505d69;
    font-size: 14px;
    list-style-type: none;
    padding: 0;
  }

  li {
    cursor: pointer;
    padding: 1em;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid #eff2f7;
    background: #fff;
    &:hover {
      background-color: rgba(86, 100, 210, .5)!important;
    }

    ul {
      cursor: pointer;
      margin: 0;
      background-color: #fff;
    }

    .wrapper {
      cursor: pointer;
      li {
        cursor: pointer;
        padding: 0.5em;
      }
    }
  }

  .chew-drp {
    float: right
  }

  .main-wrapper {
    display: none;
    &.show-wrapper {
      display: block;
    }
  }

}

.small-btn {
  font-size: 10px!important;
  padding: 5px 10px;
  line-height: 10px;
  min-height: auto;
}

.tbl-wrper {
  max-height: 569px;
  overflow-y: scroll;
  background: white;

  thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 99;
    box-shadow: 0px 2px 0px -1.8px #000000;
}


}

p.table-small {
    margin-bottom: 0;
}


.loader-loc {
  position: absolute;
  top: 5px;
  right: 0
}
.add-to-list {
  cursor: pointer;
  font-size: 24px;
  transition: all .3s ease;
  &:hover {
    opacity: .7;
  }
}

.dIMbua {
  background: white;
  padding: 0!important;
  max-height: 648px;
}


.text-1 {
  font-size: 1.2rem!important;
  font-weight: 500!important;
}

.text-2 {
    font-size: 1rem;
    font-weight: 200;
}

.progress-bar {
  transition: width 1s linear!important;
}

.ribbon {
  height: 33%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.play-pause-actor {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  display: none;
  transition: all .5s ease;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.actor-img {

  &:hover {
    .play-pause-actor {
      display: block;
    }
  }
}

.video-actor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  video {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 9%;
    transform: translateY(-60%);
  }
}

.hide {
  opacity: 0!important;
}

.close-prev-actor {
  position: absolute;
  top: 22%;
  right: 12%;
  cursor: pointer;
  transition: all .3 s ease;
  &:hover {
    opacity: .7
  }
}

.del-cust-avatar {
  display: none;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
}

.actor-img {
  &:hover {
    .del-cust-avatar {
      display: inline-block;
    }
  }
}

.placeholder-ul{
  visibility: hidden;
}
.placeholder-li:hover {
  background: #F1F1F1;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.anim {
  .progress-bar-striped {
    background-size: 50px 50px;
  }
  &.final {
    .progress-bar-striped {
      animation: bar-animation 3s linear infinite;
    }
  }
}

.input-group {
  input {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  .input-group-text {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
    border-left-color: transparent!important;
  }
}

.small-perc {
  width: 300px;
  left: 15px;
  bottom: 2%;
  z-index: 999999999;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 21px -2px #000000;
}

@keyframes bar-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0px -100px;
  }
}

.picturezoom {
  &.portrait-actor {
    max-width: 100%;
    max-height: 100%;
    transform: scale(1.2) translate(0) !important;
  }
}

.portrait-actor-parent {
  .actor-picture-height-circle {
    height: 210px!important;
  }
}

.speech-mod {
  max-width: 215px;
}

.speech-mod {
  &:hover {
    color: #5664d2!important;
  }
  &:active {
    color: #5664d2!important;
  }
  &.btn-outline-primary {
    color: #5664d2!important;
  }
}

.text-enabled-with-cloned {
  font-style: italic;
  font-size: 12px;
  a {
    text-decoration: underline!important;
    cursor: pointer;
  }
}

del {
  color: red;
}
ins {
  color: red;
}
.vars-select {
  select {
    display: inline-block;
    min-width: 150px;
  }
}
.actor-chose {
  padding-top: 5px;
  max-height: 245px;
  overflow-y: auto;
}