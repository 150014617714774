
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content }; // 600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; // 900px 56.25em
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; // 1200px
    }
    @if $breakpoint == mac {
        @media only screen and (max-width: 80em) { @content }; // 1280px
    }
    @if $breakpoint == laptop {
        @media only screen and (max-width: 90em) { @content }; // 1366px
    }
    @if $breakpoint == desktop125 {
        @media only screen and (max-width: 96em) { @content }; // 1534px
    }
    @if $breakpoint == desktop1680 {
        @media only screen and (max-width: 105em) { @content }; // 1680px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (max-width: 112.5em) { @content }; // 1800px
    }
    @if $breakpoint == all-desktop {
        @media only screen and (min-width: 105em) { @content };
    }
}


.chat {
    min-height: 100vh;
    background-size: cover!important;
    @include respond(phone) {
      min-height: 0;
      overflow: auto;
    }
    .name {
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #8dd8e0;
      padding-left: 0;
      display: inline-block;
    }
    .job-title {
      display: inline-block;
      font-size: 12px;
    }
  }
  .text-holder {
    position: relative;
    .send-msg-arr {
      position: absolute;
      height: 30px;
      border: 1px solid #5664d2;
      width: 30px;
      color: #5664d2;
      border-radius: 50%;
      cursor: pointer;
      top: 17px;
      right: 22px;
      transition: opacity .3s ease;
      background-color: transparent;
      &:hover {
        opacity: .7;
      }
      .fa-location-arrow {
        margin-top: 6px;
        margin-left: 0px;
      }
    }
  }

  .background-chat {
  }
  #video-lead {
    cursor: 'pointer';
  }
  .lead-page-main {
    background-color: transparent;
  }

  .lead-page-right {
    background-color: #fff;
    @include respond(mac) {
      min-height: 22rem;
    }
  }
  .send-msg-out {
    position: initial;
    bottom: 0px;
    left: 10px;
    right: 10px;
  }

//   .outer-iframe {
//     width: 100%;
//     position: relative;
//     padding-bottom: 56.25%; /* ratio 16/9 */
//     iframe {
//       border: none;
//       position: absolute;
//       width: 100%;
//       height: 0;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//     }
//   }

  // .iframed {


  //   .container {
  //     width: 1300px!important;
  //     max-width: 90%;


//     }
//     &.client-page {
//       overflow: hidden;
//       width: 1200px;
//     }
//     .lead-page-left {
//       width: 62%;
//     }

//     .lead-page-right {
//       width:38%;
//     }
//   }

  .full-video {
    object-fit: cover;
    width: 100%;
    height: 99.4vh;
  }

  .play-video-chat {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    font-size: 2.2rem;
    color: white;
    cursor: pointer;
}

body.client-page.iframed .play-video-chat {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.5rem;
}

textarea.form-control.chat-placeholder.mb-3 {
    padding-right: 3.5rem;
}

.cta-button {
  display: block;
  color: red;
  padding: 5px 5px;
  border-radius: 20px;
  border: 1px solid red;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  transition: opacity .3s ease;
  &:hover {
    color: red;
    opacity: .7;
  }
}

.logo {
  height: 60px!important;
  @include respond(phone) {
    height: 20px!important;
  }
}

.vjs-big-play-button {
  display: none!important;
}

.video-player {
  overflow-y: hidden;
  .player {
    position: relative;
    max-height: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: auto;
    color: #fff;
    line-height: normal;
    font-family: "Helvetica Neue",Helvetica,Arial!important;
    font-size: 10px;
    overflow: visible;
    border-collapse: separate;
    user-select: none;
    background-color: transparent;
    touch-action: manipulate;
  }
  video {
    width: 100%;
    height: 100%;
  }
  .vjs-big-play-button {
    display: none!important;
  }
  .ply-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    transition: all .3s ease;
  }
  .ply-btn:hover {
    opacity: .7;
  }
}

.video-player-body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  .video-reachout-player-wrapper {
    padding:56.25% 0 0 0;
    position:relative;
  }
}

.reachout-branding {
  position: fixed;
  right: 0;
  bottom: 0;
  transition: all .3s ease;
  width: 165px;
  height: 37px;
  &:hover {
    opacity: .9;
  }
}

.play-big-btn {
  background-image: url('./big-play-button.png');
  background-size: 20%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: heartbeat 1.5s infinite;
  cursor: pointer;
}

@keyframes heartbeat {
  0% {
    background-size: 20%
  }

  50% {
    background-size: 25%
  }

  100% {
    background-size: 20%
  }
}


.bxshadow {
  border: 1px solid rgba(0,0,0,.125);
  margin-bottom: 1rem;
}

.date-chat {
  color: #cdcdcd;
    text-decoration: none;
}


.chat-box {
  /* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  &__btnbig {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 8px;
    padding: 10px;
    background: #2f5993;
    color: #ffffff;
    font-weight: bold;
    border: 0;
    outline: 0;
    cursor: initial!important;
  }
  &__btnsmall {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    text-align: center;
    background: #182e591a;
    border-radius: 15px;
    font-size: 12px;
    padding: 4px 10px 5px 10px;
    font-weight: 700;
    color: rgba(24, 46, 89, .5);
    font-weight: bold;
    outline: 0;
    border: 0;
    cursor: initial!important;

  }
  &__btnreply {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 8px;
    text-transform: uppercase;
    padding: 15px 10px;
    background: #2c75d9;
    color: #ffffff;
    font-weight: bold;
    border: 0;
    outline: 0;
    cursor: pointer!important;
    transition: .5s ease-in-out all;
  }
}

.border-50 {
  border-radius:50%;
}

// Loading Page

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11000000;
  background: rgba(255, 255, 255, .8);
  .loader {
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
      border: 3px solid #3498db;
      z-index: 1500;
      border: 3px solid transparent;
      border-top-color: #3498db;
      border-radius: 50%;
      animation: spin 2s linear infinite;
      &:before {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          right: 5px;
          bottom: 5px;
          border: 3px solid #e74c3c;
          border: 3px solid transparent;
          border-top-color: #e74c3c;
          border-radius: 50%;
          animation: spin 3s linear infinite;
      }
      &:after {
          content: "";
          position: absolute;
          top: 15px;
          left: 15px;
          right: 15px;
          bottom: 15px;
          border: 3px solid #f9c922;
          border: 3px solid transparent;
          border-top-color: #f9c922;
          border-radius: 50%;
          animation: spin 1.5s linear infinite;
      }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.chat-new p.mail-chat {
    font-size: 0.8rem !important;
    color: gray;
}

// Analytics

.analytics {
  .analytic-badge {
    &.badge-success {
      color: #1CBB8C;
      background: rgba(28, 187, 140, .2)!important;
    }
  }

  tr.text-uppercase {
      line-height: 6rem;
      transform: translateY(-15%);
  }

  .progress {
      display: flex;
      height: .5rem;
      overflow: hidden;
      line-height: 0;
      font-size: 0.675rem;
      border-radius: 0.25rem;
      background-color: #f1f5f7;
      color: #343A40;
  }

  tr.silver-two {
      height: 7rem;
  }

  .badge.badge-pill.d-block.badge-inactive.my-3.font-nunito.py-1.px-4 {
      background: #cbced2!important;
      color: white!important;
  }

}

